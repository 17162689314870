import React from 'react';
import { SEO } from '../components/SEO';
import { StaticImage } from 'gatsby-plugin-image';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import { Layout } from '../layouts/Layout';
import classNames from 'classnames';

const facilityProps = [
  {
    index: 1,
    title: '위례에서 가장 큰 규모의 요양병원',
    detail: '297병상 규모의 대형 요양병원으로 위례에서 가장 큰 규모입니다.',
    img: (
      <StaticImage
        src="../resources/facility1.svg"
        className="w-full h-auto"
        objectFit="cover"
        objectPosition="center"
        placeholder="none"
        alt="hero"
        backgroundColor="transparent"
      />
    ),
  },
  {
    index: 2,
    title: '체계적인 재활치료실 운영',
    detail:
      '재활의학과 전문의와 치료사들의 환자 맞춤형 재활치료를 제공하고 있습니다. 최신 재활장비들로 체계적인 치료 시스템을 운영합니다.',
    img: (
      <StaticImage
        src="../resources/facility3.svg"
        className="w-full h-auto"
        objectFit="cover"
        objectPosition="center"
        placeholder="none"
        alt="hero"
        backgroundColor="transparent"
      />
    ),
  },
  {
    index: 3,
    title: 'Baxter 최신투석장비 보유',
    detail:
      '인공신장센터에는 박스터사의 최신 투석기계(AK98) 및 정수시설인 RO 시스템이 설치되어 있습니다. 투석 전문 내과 전문의와 전문 투석간호 인력이 상주하고 있습니다.',
    img: (
      <StaticImage
        src="../resources/facility7.svg"
        className="w-full h-auto"
        objectFit="cover"
        objectPosition="center"
        placeholder="none"
        alt="hero"
        backgroundColor="transparent"
      />
    ),
  },
  {
    index: 4,
    title: '의사·한의사 협진',
    detail: '한방 협진을 통한 개인별 맞춤 통합치료를 실시하고 있습니다.',
    img: (
      <StaticImage
        src="../resources/facility4.svg"
        className="w-full h-auto"
        objectFit="cover"
        objectPosition="center"
        placeholder="none"
        alt="hero"
        backgroundColor="transparent"
      />
    ),
  },
  {
    index: 5,
    title: '전 병실 통유리를 통한 채광',
    detail:
      '모든 병실은 통유리로 충분한 자연채광이 가능합니다.이는 환자분들의 면역력 증가와 심리적 안정에 도움이 될 수 있습니다.',
    img: (
      <StaticImage
        src="../resources/facility2.svg"
        className="w-full h-auto"
        objectFit="cover"
        objectPosition="center"
        placeholder="none"
        alt="hero"
        backgroundColor="transparent"
      />
    ),
  },
  {
    index: 6,
    title: '쾌적한 병실',
    detail:
      '모든 병상은 전동 침대로 구성되어 있으며, 병상 간 간격은 신법 기준인 1.5m 이상으로 배치되어 있습니다. 신축건물로 쾌적한 환경과 깨끗한 공조시스템이 상시 가동 중입니다.',
    img: (
      <StaticImage
        src="../resources/facility9.svg"
        className="w-full h-auto"
        objectFit="cover"
        objectPosition="center"
        placeholder="none"
        alt="hero"
        backgroundColor="transparent"
      />
    ),
  },
  {
    index: 7,
    title: '삼성 웰스토리 식자재 사용, 대규모 식당시설 보유',
    detail:
      '대규모 병실에 걸맞는 대규모 식당시설과 전문 영양사가 상주하고 있습니다.당뇨식, 투석식, 연하곤란식 등 환자들에게 맞는 식단을 제공하고 있습니다.',
    img: (
      <StaticImage
        src="../resources/facility8.svg"
        className="w-full h-auto"
        objectFit="cover"
        objectPosition="center"
        placeholder="none"
        alt="hero"
        backgroundColor="transparent"
      />
    ),
  },
  {
    index: 8,
    title: '양질의 간병 서비스',
    detail:
      '제공환자분들의 요양을 돕기 위한 꾸준한 간병인 교육이 이루어지며, 다양한 간병 형태로 입원 문의가 가능합니다.',
    img: (
      <StaticImage
        src="../resources/facility5.svg"
        className="w-full h-auto"
        objectFit="cover"
        objectPosition="center"
        placeholder="none"
        alt="hero"
        backgroundColor="transparent"
      />
    ),
  },
  {
    index: 9,
    title: '넓은 주차장 시설',
    detail:
      '건물 지하2층부터 지하 5층까지 모두 이용 가능한 넓은 주차장이 있습니다.화재에 안전합니다 병원 바로 옆에 소방서가 위치해 있습니다. 신축 건물에 스프링클러와 자동개폐장치 등 안전시설을 포함해 신법기준에 맞춘 소방시설을 완비하였습니다.',
    img: (
      <StaticImage
        src="../resources/facility6.svg"
        className="w-full h-auto"
        objectFit="cover"
        objectPosition="center"
        placeholder="none"
        alt="hero"
        backgroundColor="transparent"
      />
    ),
  },
];

const Facility = ({ index, img, title, detail }) => {
  return (
    <div className={classNames('w-full overflow-hidden p-4 flex flex-col items-center justify-center text-black')}>
      <div className={classNames('relative w-auto h-auto overflow-hidden max-w-[280px] max-h-[280px]')}>{img}</div>
      <div className="text-gray-800 p-5 my-5 flex flex-col text-[16px] w-full gap-4">
        <span className="bg-slate flex items-center justify-center text-[14px] text-white rounded-full w-[30px] h-[30px]">
          {('0' + index).slice(-2)}
        </span>
        <h2 className="flex items-end text-[22px] font-bold whitespace-pre-wrap" style={{ wordBreak: 'keep-all' }}>
          {title}
        </h2>
        <p className="text-[18px] whitespace-pre-wrap" style={{ wordBreak: 'keep-all' }}>
          {detail}
        </p>
      </div>
    </div>
  );
};

const Section = ({ className = '', children }) => {
  return (
    <div
      className={classNames(
        'relative flex flex-col overflow-x-hidden items-center justify-center w-full m-auto text-white max-w-screen-sm mx-auto',
        className
      )}
    >
      {children}
    </div>
  );
};

export default function FacilityPage(props) {
  return (
    <Layout className="relative bg-white">
      <SEO title={null} titleTemplate="" />
      <Header color noHeight className="sticky top-0 left-0 right-0 z-10 flex" />
      <Section className="pt-16">
        {facilityProps.map((props) => (
          <Facility key={props.index} {...props} />
        ))}
      </Section>
      <Footer />
    </Layout>
  );
}
